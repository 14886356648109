import { useEffect, useRef, useState } from "react";
import { Stack, Popper, Popover, ClickAwayListener } from "@mui/material";
import Plot from 'react-plotly.js';
import OverlayClaimView from 'components/conversations/OverlayClaimView'


function getColor(type, opacity) {

    switch (type) {
        case 'CON':
            return `rgba(250, 27, 27, ${opacity})`
        case 'PRO':
            return `rgba(12, 204, 18, ${opacity})`
        default:
            return `rgba(5, 227, 227, ${opacity})`
    }
}

const typeDict = {}
const claimDict = {}


export default function SunburstView({ data, onClick }) {

    const [colors, setColors] = useState([])
    const [parents, setParents] = useState([])
    const [labels, setLabels] = useState([])
    const [ids, setIds] = useState([])
    const [claim, setClaim] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const ref = useRef()

    useEffect(() => {
        const newColors = []
        const newParents = []
        const newIds = []
        const newLabels = []

        const dfs = (root, parent = null) => {
            newLabels.push(root?.text)
            newIds.push(root?.id?.toString())
            newParents.push(parent ? parent?.id?.toString() : '')
            newColors.push(getColor(root?.type, 1))
            typeDict[root.id] = root?.type
            claimDict[root.id] = root
            // claimDict[root.id] = { ...root }
            // delete claimDict[root.id]?.children

            if ('children' in root) {
                for (let node of root?.children.sort((a, b) => (a.type > b.type) ? 1 : -1)) {
                    dfs(node, root)
                }
            }
        }
        dfs(data)
        setColors(newColors)
        setParents(newParents)
        setIds(newIds)
        setLabels(newLabels)
    }, [])


    const chartData = [{
        type: "sunburst",
        ids: ids,
        labels: labels,
        parents: parents,
        leaf: { opacity: 1 },
        sort: false,
        textinfo: 'none',
        hoverinfo: 'none',
        marker: {
            line: { width: 2 },
            colors: colors,
        },
    }]

    var layout = {
        margin: { l: 0, r: 0, b: 0, t: 0 },
        width: 500,
        height: 500,
    }

    const handlePopoverOpen = (event) => {
        if (open || !ref || event.points[0].id === 'root') return
        setAnchorEl(ref.current)
    };

    const handlePopoverClose = () => {
        const newColors = ids.map(id => getColor(typeDict[id], 1))
        setColors(newColors)
        setClaim(null)
        setAnchorEl(null)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return <Stack>
        <Plot
            data={chartData}
            layout={layout}
            config={{ displayModeBar: false }}
            onSunburstClick={(e) => {
                if (e?.points[0]?.id === 'root') {
                    onClick(null)
                } else {
                    onClick(claimDict[e?.points[0]?.id])
                }
                return false
            }}
            onHover={(e) => {
                handlePopoverOpen(e)
                e.points.map(d => {
                    const newColors = ids.map(id => (id === d.id) ? (
                        getColor(typeDict[id], 1)
                    ) : getColor(typeDict[id], 0.3))
                    setColors(newColors)
                    setClaim(claimDict[d.id])
                })
            }}
            onUnhover={(e) => {
                if (e.event?.toElement?.__data__ !== 0 && 
                    e.event?.toElement?.__data__?.data?.id !== 'root') return
                handlePopoverClose()
            }}
        />
        <div ref={ref} />
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement='bottom'
                sx={{ zIndex: 2000 }}>
                <OverlayClaimView claim={claim} />
            </Popper>
        </ClickAwayListener>
    </Stack>
}
