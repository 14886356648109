import AnalyticsIcon from '@mui/icons-material/Analytics'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import URL from 'route/url'

export default function InsightButton({conversation, ...props}) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate(URL.getInsightURL(conversation.id))
    }

    return <Tooltip title='Insight'>
        <IconButton {...props} onClick={onClick} >
            <AnalyticsIcon fontSize="inherit" />
        </IconButton>
    </Tooltip>
}

