export default class URL {

    static USERNAME_TEMPLATE = ':username'
    static CONVERSATION_ID_TEMPLATE = ':conversationId'
    static TAG_TEMPLATE = ':tag'

    static ROOT = '/'

    static LOGIN = '/login'
    static VERIFY_EMAIL = '/verify-email'

    static FEED = '/feed'
    static CONVERSATIONS = '/conversations'

    static PROFILE = '/profile'
    static getProfileURL = (profileId) => `${this.PROFILE}/${profileId}`
    static getProfileURLTemplate = () => this.getProfileURL(this.USERNAME_TEMPLATE)

    static CONVERSATION = '/conversation'
    static getConversationURL = (conversationId) => `${this.CONVERSATION}/${conversationId}`
    static getConversationURLTemplate = () => this.getConversationURL(this.CONVERSATION_ID_TEMPLATE)

    static INSIGHT = '/insight'
    static OVERVIEW = 'overview'
    static getInsightURL = (conversationId) => `${this.INSIGHT}/${conversationId}`
    static getInsightURLTemplate = () => this.getInsightURL(this.CONVERSATION_ID_TEMPLATE)
    static getInsightOverviewURL = (conversationId) => `${this.getInsightURL(conversationId)}/${this.OVERVIEW}`
    static getInsightOverviewByTagURL = (conversationId, tag) => `${this.getInsightOverviewURL(conversationId)}/${tag}`
    static getOverviewByTagURLTemplate = () => `${this.OVERVIEW}/${this.TAG_TEMPLATE}`

}