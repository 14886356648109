
import { useState, useEffect } from 'react'
import { Stack, IconButton, Slide } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import TopClaimView from 'pages/claims/claim-tree/TopClaimView'
import MultipleClaimsView from 'pages/claims/claim-tree/MultipleClaimsView'


const slideTime = 250


const searchNode = (rootClaims, parentNode) => {
    if (!parentNode) return rootClaims

    for (let cl of rootClaims) {
        if (cl.id === parentNode.id) return cl.children
        let val = searchNode(cl.children, parentNode)
        if (val) return val
    }
}


export default function ClaimsTraverseView({
    conversation,
    claims,
    modifyClaims,
    topClaim = null,
}) {

    const [curentClaims, setCurrentClaims] = useState([])
    const [currentTopClaim, setCurrentTopClaim] = useState(null)
    const [openEnded, setOpenEnded] = useState(false)
    const [randomKey, setRandomKey] = useState(Math.random())
    const [direction, setDirection] = useState('up')
    const [slideIn, setSlideIn] = useState(true)

    useEffect(() => {
        if (!conversation) return
        if (topClaim) {
            setCurrentTopClaim(topClaim)
            setOpenEnded(false)
            setCurrentClaims(topClaim.children)
        } else {
            setCurrentClaims(claims)
            setOpenEnded(conversation?.open_ended)
        }
    }, [conversation, topClaim])

    useEffect(() => {
        let newClaims = searchNode(claims, currentTopClaim)
        setCurrentClaims(newClaims)
    }, [claims])

    const slideUp = () => {
        setDirection('down')
        setSlideIn(false)
        setTimeout(() => {
            setRandomKey(Math.random())
            setDirection('up')
            setSlideIn(true)
        }, slideTime / 2)
    }

    const slideDown = () => {
        setDirection('up')
        setSlideIn(false)
        setTimeout(() => {
            setRandomKey(Math.random())
            setDirection('down')
            setSlideIn(true)
        }, slideTime / 2)
    }

    const onDescentClick = (claim) => {

        slideUp()
        setCurrentClaims(claim?.children)
        setCurrentTopClaim(claim)
        setOpenEnded(false)
    }

    const onAscentClick = () => {
        if (!currentTopClaim) return

        slideDown()

        if (claims.map(c => c.id).includes(currentTopClaim.id)) {
            setCurrentClaims([...claims])
            setCurrentTopClaim(null)
            setOpenEnded(conversation?.open_ended)
            return
        }

        const dfs = (rootClaims) => {
            var val = null
            for (let cl of rootClaims) {
                if (cl.children.map(c => c.id).includes(currentTopClaim.id)) {
                    return [cl.children, cl]
                } else {
                    val = dfs(cl.children)
                    if (val) return val
                }
            }
        }
        const [children, parent] = dfs(claims)
        setCurrentClaims([...children])
        setCurrentTopClaim(parent)
    }

    return <Slide
        key={randomKey}
        direction={direction}
        in={slideIn}
        mountOnEnter
        timeout={slideTime}
        unmountOnExit>
        <Stack width='80%' spacing={1} alignItems='center'>
            <IconButton
                onClick={onAscentClick}
                color='primary'
                disabled={!Boolean(currentTopClaim)}>
                <ArrowCircleUpIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <TopClaimView
                conversation={conversation}
                claim={currentTopClaim} />
            <MultipleClaimsView
                claims={curentClaims}
                onDescentClick={onDescentClick}
                modifyClaims={modifyClaims}
                conversation={conversation}
                topClaim={currentTopClaim}
                openEnded={openEnded} />
        </Stack >
    </Slide>
}