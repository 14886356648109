import BaseClaimView from 'components/conversations/BaseClaimView'
import { Stack } from '@mui/material'
import AddClaimView from 'pages/claims/claim-tree/AddClaimView'


export default function UnaryView({
    claims,
    conversation,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    return <Stack spacing={1} sx={{ width: '100%' }}>
        <AddClaimView type='ANS'
            modifyClaims={modifyClaims}
            topClaim={topClaim}
            conversation={conversation} />
        {claims.map(claim => {
            return <BaseClaimView
                onClick={() => onDescentClick(claim)}
                key={claim.id}
                conversation={conversation}
                claim={claim} />
        })}
    </Stack>
}