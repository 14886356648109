import { Navigate, useRoutes } from 'react-router-dom'
import PrivateRoute from 'route/PrivateRoute'
import GuestRoute from 'route/GuestRoute'
import LoginContainer from 'pages/login/LoginContainer'
import ConversationFinder from 'pages/finder/ConversationFinder'
import ClaimsView from 'pages/claims/ClaimsView'
import MainLayout from 'layout/MainLayout'
import VerifyEmailView from 'pages/login/VerifyEmailView'
import ConversationLayout from 'layout/ConversationLayout'
import Overview from 'pages/insight/overview/Overview'
import ProfilePage from 'pages/profile/ProfilePage'
import ProfileOwnerRoute from './ProfileOwnerRoute'
import FeedView from 'pages/feed/FeedView'
import AdminRoute from 'route/AdminRoute'
import URL from 'route/url'


export default function Router() {
    return useRoutes([
        {
            path: URL.LOGIN,
            element: (
                <GuestRoute>
                    <LoginContainer />
                </GuestRoute>
            ),
        },
        {
            path: URL.VERIFY_EMAIL,
            element: (
                <GuestRoute>
                    <VerifyEmailView />
                </GuestRoute>
            ),
        },
        {
            path: URL.ROOT,
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                { element: <Navigate to={URL.CONVERSATIONS} replace />, index: true },
                { path: URL.CONVERSATIONS, element: <ConversationFinder /> },
                { path: URL.FEED, element: <FeedView /> }
            ]
        },
        {
            path: URL.PROFILE,
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                {
                    path: URL.USERNAME_TEMPLATE,
                    element: <ProfileOwnerRoute>
                        <ProfilePage />
                    </ProfileOwnerRoute>
                },
            ]
        },
        {
            element: (
                <PrivateRoute>
                    <ConversationLayout />
                </PrivateRoute>
            ),
            children: [
                { path: URL.getConversationURLTemplate(), element: <ClaimsView /> },
                {
                    path: URL.getInsightURLTemplate(),
                    children: [
                        {
                            element: <AdminRoute>
                                <Navigate to={URL.OVERVIEW} replace />
                            </AdminRoute>, index: true
                        },
                        {
                            path: URL.getOverviewByTagURLTemplate(),
                            element: <AdminRoute>
                                <Overview />
                            </AdminRoute>
                        },
                        {
                            path: URL.OVERVIEW,
                            element: <AdminRoute>
                                <Overview />
                            </AdminRoute>
                        },
                    ]
                },

            ]
        },
        { path: '*', element: <Navigate to={URL.ROOT} replace /> },
    ])
}