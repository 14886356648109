
import { useState } from 'react'
import { Stack, Paper, Typography, IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Colors from 'components/visualizations/colors'
import CreateClaimFormContainer from 'pages/claims/CreateClaimFormContainer'


export default function AddClaimView({ type, conversation, topClaim, modifyClaims }) {

    const [open, setOpen] = useState(false)

    var color = null
    var text = null

    switch (type) {
        case 'CON':
            color = Colors.CON
            text = 'Cons'
            break
        case 'PRO':
            color = Colors.PRO
            text = 'Pros'
            break
        case 'ANS':
            color = Colors.ANS
            text = 'Answers'
            break
    }

    const onClick = () => {
        setOpen(true)
    }

    const close = ({ claim = null }) => {
        setOpen(false)
        if (!claim) return
        modifyClaims(claim, topClaim)
    }

    return <Paper elevation={0} sx={{ width: '100%', padding: 2 }}>
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h6' color={color}>
                <b>{text}</b>
            </Typography>
            <IconButton onClick={onClick}>
                <AddCircleIcon sx={{ fontSize: 40, color: color }} />
            </IconButton>
            {open && <CreateClaimFormContainer
                open={open}
                onClose={close}
                conversation={conversation}
                type={type}
                parent={topClaim}
            />}
        </Stack>
    </Paper>
}