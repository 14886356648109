import UnaryView from 'pages/claims/claim-tree/UnaryView'
import BinaryView from 'pages/claims/claim-tree/BinaryView'


export default function MultipleClaimsView({
    claims,
    conversation,
    openEnded,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    if (openEnded) {
        return <UnaryView
            onDescentClick={onDescentClick}
            modifyClaims={modifyClaims}
            claims={claims}
            topClaim={topClaim}
            conversation={conversation} />
    } else {
        const proClaims = claims.filter(claim => claim.type === 'PRO')
        const conClaims = claims.filter(claim => claim.type === 'CON')
        return <BinaryView
            onDescentClick={onDescentClick}
            modifyClaims={modifyClaims}
            conversation={conversation}
            topClaim={topClaim}
            proClaims={proClaims}
            conClaims={conClaims} />
    }
}