
import {useEffect, useState} from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth } from 'components/login/firebaseAuth'
import { UserAPI } from 'api/userAPI'


export default function useIsAdmin() {
    const [user, loading, error] = useAuthState(Auth.getAuth())
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!user || loading) return
        UserAPI.getUser({
            username: user.uid,
            onSuccess: res => {
                setIsAdmin(res.data?.is_admin)
                setIsLoading(false)
            },
            onFailure: () => setIsLoading(false)
        })
    }, [])

    return [isAdmin, isLoading]

}

