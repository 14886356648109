import { styled, alpha } from '@mui/material/styles'
import {
    Box,
    Stack,
    AppBar,
    Toolbar,
    Tabs,
    Tab
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import URL from 'route/url'

import { HEADER } from './constants'
import LogoutButton from 'components/header/buttons/LogoutButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import ProfileButton from './buttons/ProfileButton'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import useIsAdmin from 'hooks/useIsAdmin'
import logo from 'images/logo.svg'


function cssStyles(theme) {
    return {
        bgBlur: (props) => {
            const color = props?.color || theme?.palette.background.default || '#000000';

            const blur = props?.blur || 6;
            const opacity = props?.opacity || 0.8;

            return {
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
                backgroundColor: alpha(color, opacity),
            };
        }
    }
}


const RootStyle = styled(AppBar)(({ theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    color: 'inherit',
    height: HEADER.MOBILE_HEIGHT,
    // zIndex: theme.zIndex.appBar + 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: '100%',
    },
}))


export default function ConversationHeader({value, setValue}) {

    const navigate = useNavigate()
    const { conversationId } = useParams()
    const [isAdmin, isLoading] = useIsAdmin()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 0) {
            navigate(URL.getConversationURL(conversationId))
        } else if (newValue === 1) {
            navigate(URL.getInsightURL(conversationId))
        } else if (newValue === 'home') {
            navigate(URL.FEED)
        } else if (newValue === 'find') {
            navigate(URL.CONVERSATIONS)
        }
    }

    return (
        <RootStyle>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 },
                }}
            >
                <img src={logo} alt='landing' style={{ height: 30 }} />
                {/* <BackButton/> */}
                <Box sx={{ flexGrow: 1 }} />
                <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
                    <Tab value='home' icon={<HomeRoundedIcon />} label='Home'/>
                    <Tab value='find' icon={<TravelExploreIcon />} label='Find'/>
                    <Tab value={0} icon={<RemoveRedEyeIcon />} label='Conversation' />
                    {isAdmin && <Tab value={1} icon={<AnalyticsIcon />} label='Insight' />}
                </Tabs>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <LogoutButton />
                    <ProfileButton />
                </Stack>
            </Toolbar>
        </RootStyle>
    )
}