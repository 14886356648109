import { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, Tabs, Tab } from '@mui/material'
import { HEADER } from './constants'
import LogoutButton from 'components/header/buttons/LogoutButton'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ProfileButton from './buttons/ProfileButton'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import logo from 'images/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import URL from 'route/url'


function cssStyles(theme) {
    return {
        bgBlur: (props) => {
            const color = props?.color || theme?.palette.background.default || '#000000';

            const blur = props?.blur || 6;
            const opacity = props?.opacity || 0.8;

            return {
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
                backgroundColor: alpha(color, opacity),
            };
        }
    }
}


const RootStyle = styled(AppBar)(({ theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    color: 'inherit',
    zIndex: theme.zIndex.drawer + 1,
    height: HEADER.MOBILE_HEIGHT,
    // zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: '100%',
    },
}))

function getInitValue() {
    const val = window.location.pathname.split('/')[1]
    if (val === 'conversations') return 1
    if (val === 'feed') return 0
    return false
}


export default function MainHeader() {

    const [value, setValue] = useState(getInitValue())
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        switch (location.pathname.replaceAll('/', '')) {
            case 'feed':
                setValue(0)
                break
            case 'conversations':
                setValue(1)
                break
            default:
                setValue(false)
        }
    }, [location])


    const handleChange = (event, newValue) => {
        setValue(newValue)
        switch (newValue) {
            case 0:
                navigate(URL.FEED)
                break
            case 1:
                navigate(URL.CONVERSATIONS)
                break
        }
    }
    return (
        <RootStyle>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 },
                }}
            >
                <img src={logo} alt='landing' style={{ height: 30 }} />
                <Box sx={{ flexGrow: 1 }} />
                <Tabs
                    value={value} 
                    onChange={handleChange} 
                    selectionFollowsFocus={true}
                    aria-label="icon label tabs example">
                    <Tab icon={<HomeRoundedIcon />} label='Home'/>
                    <Tab icon={<TravelExploreIcon />} label='Find'/>
                </Tabs>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <LogoutButton />
                    <ProfileButton />
                </Stack>
            </Toolbar>
        </RootStyle>
    )
}