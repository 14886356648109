import { useEffect, useState } from 'react'
import { TagAPI } from 'api/tags'
import {
    Stack,
    TextField,
    Autocomplete,
    Button,
    Box,
    Typography
} from '@mui/material'


function TagButton({ tag, tags, setTags }) {

    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        setIsChecked(tags.includes(tag))
    }, [tags])


    const onClick = (e) => {
        setIsChecked(!isChecked)
        console.log(tag.name, tags.includes(tag))
        if (isChecked) {
            setTags(tags.filter(item => item.name !== tag.name))
        } else {
            if (!tags.includes(tag)) setTags([...tags, tag])
        }
    }

    return <Button
        disableElevation
        onClick={onClick}
        sx={{ borderRadius: 5, minWidth: 70, textTransform: 'none', margin: 0.5 }}
        variant={isChecked ? 'contained' : 'outlined'}>
        {tag?.name}
    </Button>
}

function TagButtonList({ options, tags, setTags }) {
    return <Box
        sx={{ width: '100%' }}>
        {options.map(tag => <TagButton key={tag.name} tag={tag} tags={tags} setTags={setTags}/>)}
    </Box>
}


export default function TagsAutocomplete({ tags, setTags }) {

    const [options, setOption] = useState([])

    useEffect(() => {
        TagAPI.getTags({
            page: 1,
            onSuccess: res => {
                setOption(res.data.results)
            }
        })
    }, [])

    return <Stack spacing={2}>
        <Autocomplete
            freeSolo={true}
            options={options}
            multiple
            filterSelectedOptions
            value={tags}
            getOptionLabel={(option) => option.name}
            onChange={(event, value, reason, details) => setTags(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Add Tags"
                    placeholder="Tags"
                />
            )}
        />
        <Typography color='text.secondary'>
            <b>Commonly used tags</b>
        </Typography>
        <TagButtonList options={options.slice(0, 10)} tags={tags} setTags={setTags} />
    </Stack>



}