import BaseClaimView from 'components/conversations/BaseClaimView'
import { Stack, Grid } from '@mui/material'
import AddClaimView from 'pages/claims/claim-tree/AddClaimView'


export default function BinaryView({
    proClaims,
    conClaims,
    conversation,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    return <Grid container spacing={1}>
        <Grid item xs={6}>
            <Stack spacing={1} >
                <AddClaimView
                    type='PRO'
                    modifyClaims={modifyClaims}
                    conversation={conversation}
                    topClaim={topClaim} />
                {proClaims.map(claim => {
                    return <BaseClaimView
                        key={claim.id}
                        conversation={conversation}
                        onClick={() => onDescentClick(claim)}
                        claim={claim} />
                })}
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack spacing={1} >
                <AddClaimView
                    type='CON'
                    modifyClaims={modifyClaims}
                    conversation={conversation}
                    topClaim={topClaim} />
                {conClaims.map(claim => {
                    return <BaseClaimView
                        key={claim.id}
                        conversation={conversation}
                        onClick={() => onDescentClick(claim)}
                        claim={claim} />
                })}
            </Stack>
        </Grid>
    </Grid>
}