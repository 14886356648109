import { Navigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth } from 'components/login/firebaseAuth'
import CircularProgress from '@mui/material/CircularProgress'
import useIsAdmin from 'hooks/useIsAdmin'
import URL from 'route/url'



export default function PrivateRoute({ children }) {
    const [isAdmin, isLoading] = useIsAdmin()
    let location = useLocation()

    if (isLoading) {
        return <CircularProgress />
    } else if (!isAdmin) {
        return <Navigate to={URL.CONVERSATIONS} state={{ from: location }} replace />
    } 

    return children
}