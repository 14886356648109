import Modal from 'components/views/Modal'
import SunburstView from 'components/visualizations/SunburstView'
import TagsView from 'components/conversations/TagsView'
import { Stack, CircularProgress, Typography } from '@mui/material'
import String from 'utils/strings'


export default function SimpleWheelView({ data, conversation, open, onClick, onClose }) {

    const treeData = {
        id: 'root',
        type: 'ANS',
        tags: conversation?.tags,
        text: conversation?.question,
        children: data
    }

    return <Modal open={open} onClose={onClose}>
        <Stack
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ maxWidth: '60vw' }}
            spacing={3}>
            {Object.keys(treeData).length === 0 ? <CircularProgress /> : <SunburstView data={treeData} onClick={onClick} />}
            <Typography variant='h6'>
                {String.getLinks(conversation?.question)}
            </Typography>
            <TagsView tags={conversation?.tags} />
        </Stack>
    </Modal>
}