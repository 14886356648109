
import {useState, useEffect} from 'react'
import {
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio
} from '@mui/material'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import { VoteAPI } from 'api/votes'
 


export default function VoteView({ claim }) {

    const [vote, setVote] = useState(null)
    const [upVotes, setUpVotes] = useState(0)
    const [downVotes, setDownVotes] = useState(0)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setUpVotes(claim.up_votes)
        setDownVotes(claim.down_votes)
        setDisabled(true)
        VoteAPI.getVote({
            claim,
            onSuccess: (res) => {
                if (res.data.results.length === 1) {
                    setVote(res.data.results[0])
                }
                setDisabled(false)
            },
            onFailure: () => setDisabled(false)
        })
    }, [])

    const handleChange = event => {
        setDisabled(true)
        if (!vote) {
            VoteAPI.createVote({
                claim,
                value: event.target.value,
                onSuccess: (res) => {
                    setVote(res.data)
                    if (event.target.value === '1') {
                        setUpVotes(upVotes + 1)
                    } else {
                        setDownVotes(downVotes + 1)
                    }
                    setDisabled(false)
                },
                onFailure: () => setDisabled(false)
            })
        } else {
            VoteAPI.updateVote({
                vote,
                value: event.target.value,
                onSuccess: (res) => {
                    setVote(res.data)
                    if (event.target.value === '1') {
                        setUpVotes(upVotes + 1)
                        setDownVotes(downVotes - 1)
                    } else {
                        setUpVotes(upVotes - 1)
                        setDownVotes(downVotes + 1)
                    }
                    setDisabled(false)
                },
                onFailure: () => setDisabled(false)
            })

        }
    }

    const onClick = (event) => {
        event.stopPropagation()
        if (event.target.value === vote?.value.toString()) {
            setDisabled(true)
            VoteAPI.deleteVote({
                vote,
                onSuccess: (res) => {
                    setVote(null)
                    if (event.target.value === '1') {
                        setUpVotes(upVotes - 1)
                    } else {
                        setDownVotes(downVotes - 1)
                    }
                    setDisabled(false)
                },
                onFailure: () => setDisabled(false)
            })
        }
    }

    return <FormControl disabled={disabled}>
        <RadioGroup row 
          value={vote?.value || 0}
          onChange={handleChange}>
            <FormControlLabel value={1} control={
                <Radio
                    onClick={onClick} 
                    icon={<ThumbUpOffAltIcon />}
                    checkedIcon={<ThumbUpAltIcon />}
                />
            } label={upVotes} />
            <FormControlLabel value={-1} control={
                <Radio
                onClick={onClick}
                    icon={<ThumbDownOffAltIcon />}
                    checkedIcon={<ThumbDownAltIcon />}
                />
            } label={downVotes} />
        </RadioGroup>
    </FormControl>
}