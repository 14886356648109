import BaseAPI from 'api/baseAPI'


export class UserAPI { 

    static getUser({
        username,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        BaseAPI.call({
            type: 'get', 
            path: `login/users/${username}`, 
            onSuccess,
            onFailure
        })
    }

}