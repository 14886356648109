import { Outlet } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import { HEADER } from 'components/header/constants'
import MainHeader from 'components/header/MainHeader'
import ErrorBoundary from 'components/errors/ErrorBoundary'


const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  minHeight: 0,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: '100%',
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    })
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <MainHeader />

      <MainStyle >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </MainStyle>
    </Box>
  )
}