import { useState, useEffect } from 'react'
import {
    useParams,
    useOutletContext,
    useLocation
} from "react-router-dom";
import {
    Stack,
    Typography,
    Box,
    CircularProgress
} from '@mui/material'
import { ClaimAPI } from 'api/claims'
import ClaimsTraverseView from 'pages/claims/claim-tree/ClaimsTraverseView'
import String from 'utils/strings'
import ConversationSurveyForm from 'components/conversations/ConversationSurveyForm'
import ColorWheelButton from 'components/views/buttons/ColorWheelButton'
import SimpleWheelView from 'pages/wheelView/SimpleWheelView';


export default function ClaimsView() {

    const { conversation } = useOutletContext()
    const [claims, setClaims] = useState([])
    const [claimsLoading, setClaimsLoading] = useState(false)
    const [openWheelView, setOpenWheelView] = useState(false)
    const { conversationId } = useParams()
    const { state } = useLocation()
    const [topClaim, setTopClaim] = useState(null)

    useEffect(() => {
        if (!state) return
        setTopClaim(state.topClaim)
    }, [state])

    useEffect(() => {
        setClaimsLoading(true)
        ClaimAPI.getTreeClaims({
            conversationId: conversationId,
            onSuccess: (res) => {
                setClaimsLoading(false)
                setClaims(res.data.results)
            },
            onFailure: (res) => {
                setClaimsLoading(false)
            }
        })
    }, [])


    const modifyClaims = (newClaim, parent) => {

        const newClaims = [...claims]
        if (!parent) {
            newClaims.unshift(newClaim)
            setClaims(newClaims)
            return
        }

        const dfs = (rootClaims) => {
            for (var i = 0; i < rootClaims.length; i++) {
                if (rootClaims[i].id === parent.id) {
                    rootClaims[i].children.unshift(newClaim)
                    return
                } else {
                    dfs(rootClaims[i].children)
                }
            }
        }

        dfs(newClaims)
        setClaims(newClaims)
    }

    const onClick = () => setOpenWheelView(true)
    const onClose = () => setOpenWheelView(false)
    const onWheelClick = (claim) => {
        setTopClaim(claim)
        setOpenWheelView(false)
    }

    return <Box sx={{ padding: 3, overflow: 'auto', height: '90vh' }}>
        <Stack spacing={2} alignItems='center'>
            <Stack alignItems='center'>
            <ColorWheelButton onClick={onClick}/>
                <Typography variant='h5' align='center'>
                    {String.getLinks(conversation?.question)}
                </Typography>
                {conversation && !conversation?.open_ended && <ConversationSurveyForm conversation={conversation} />}
            </Stack>
            {claimsLoading ? <CircularProgress /> : <ClaimsTraverseView
                modifyClaims={modifyClaims}
                conversation={conversation}
                claims={claims}
                topClaim={topClaim} />}
        </Stack>
        {openWheelView && <SimpleWheelView 
        open={openWheelView} 
        onClick={onWheelClick}
        onClose={onClose}
        conversation={conversation}
        data={claims}
        />}
    </Box>
}